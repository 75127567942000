var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.infoUser !== '')?_c('div',[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Contact Information")])],1),_c('div',{staticClass:"d-flex flex-wrap mt-2"},[_c('b-button',{attrs:{"variant":_vm.inhabilitar === true ? 'primary' : 'outline-secondary'},on:{"click":function($event){_vm.inhabilitar = false}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Edit")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),(_vm.inhabilitar === false)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":_vm.inhabilitar === true ? 'primary' : 'primary'},on:{"click":_vm.saveChanges}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Save")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1),(_vm.$store.getters['Users/userData'].user.role.id === 1 ||
    _vm.$store.getters['Users/userData'].user.role.id === 2 ||
    _vm.$store.getters['Users/userData'].user.role.id === 3
    )?_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Telephone Number"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.optionsCleave.phone,"placeholder":"1234 567 8900","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true},model:{value:(_vm.infoUser.corporate_account_personal_info.telephone_number),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "telephone_number", $$v)},expression:"infoUser.corporate_account_personal_info.telephone_number"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,3503802419)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Email notifications"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info.email),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "email", $$v)},expression:"infoUser.corporate_account_personal_info.email"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,2135374839)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Website"}},[_c('b-form-input',{attrs:{"id":"autocomplete","disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info.website),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "website", $$v)},expression:"infoUser.corporate_account_personal_info.website"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Fax Number"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":30},model:{value:(_vm.infoUser.corporate_account_personal_info.fax_number),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "fax_number", $$v)},expression:"infoUser.corporate_account_personal_info.fax_number"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,949297118)})],1)],1)],1),(_vm.$store.getters['Users/userData'].user.role.id != 3)?_c('div',[(_vm.infoUser.amera_user.role.role!=='Corporate Account')?_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Login Information")])],1):_vm._e(),_c('b-row',[(_vm.infoUser.amera_user.role.role!=='Corporate Account')?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":30},model:{value:(_vm.infoUser.corporate_account_personal_info.email),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "email", $$v)},expression:"infoUser.corporate_account_personal_info.email"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,3220779121)})],1)],1):_vm._e(),(_vm.infoUser.amera_user.role.role!=='Corporate Account')?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"password"}},[_c('b-form-input',{attrs:{"type":"password","id":"autocomplete","disabled":_vm.inhabilitar === true,"maxlength":30},model:{value:(_vm.infoUser.corporate_account_personal_info.password),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "password", $$v)},expression:"infoUser.corporate_account_personal_info.password"}})],1)],1):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Additional contact")]),(_vm.inhabilitar === false &&
        _vm.$store.getters['Users/userData'].user.role.id != 3
        )?_c('b-button',{staticClass:"btn btn-primary ml-4",on:{"click":function($event){return _vm.addMoreAdditionalInfo()}}},[_vm._v(" Add New ")]):_vm._e()],1),_vm._l((_vm.infoUser.amerPersonalInfo),function(amerPersonalInfo,index){return _c('b-row',{key:index,staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Contact Name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(amerPersonalInfo.additional_contact_name),callback:function ($$v) {_vm.$set(amerPersonalInfo, "additional_contact_name", $$v)},expression:"amerPersonalInfo.additional_contact_name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Contact Number"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(amerPersonalInfo.additional_contact_number),callback:function ($$v) {_vm.$set(amerPersonalInfo, "additional_contact_number", $$v)},expression:"amerPersonalInfo.additional_contact_number"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Contact Email"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(amerPersonalInfo.additional_contact_email),callback:function ($$v) {_vm.$set(amerPersonalInfo, "additional_contact_email", $$v)},expression:"amerPersonalInfo.additional_contact_email"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Notes"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":250},model:{value:(amerPersonalInfo.notes),callback:function ($$v) {_vm.$set(amerPersonalInfo, "notes", $$v)},expression:"amerPersonalInfo.notes"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,true)})],1)],1),(_vm.$store.getters['Users/userData'].user.role.id != 3)?_c('b-col',{staticClass:"delete_button",attrs:{"cols":"12","md":"6","lg":"8"}},[_c('b-form-group',[_c('b-icon-trash-fill',{directives:[{name:"show",rawName:"v-show",value:(index != 0),expression:"index != 0"}],staticClass:"h2",attrs:{"tooltip.hover.top":"","variant":"danger"},on:{"click":function($event){return _vm.removeAdditionalInfo(index)}}})],1)],1):_vm._e()],1)})],2):_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Telephone Number"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.optionsCleave.phone,"placeholder":"1234 567 8900","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true},model:{value:(_vm.infoUser.corporate_account_personal_info.telephone_number),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "telephone_number", $$v)},expression:"infoUser.corporate_account_personal_info.telephone_number"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,3503802419)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info.email),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "email", $$v)},expression:"infoUser.corporate_account_personal_info.email"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,2135374839)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Website"}},[_c('b-form-input',{attrs:{"id":"autocomplete","disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info.website),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "website", $$v)},expression:"infoUser.corporate_account_personal_info.website"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Fax Number"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.optionsCleave.phone,"placeholder":"1234 567 8900","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true},model:{value:(_vm.infoUser.corporate_account_personal_info.fax_number),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "fax_number", $$v)},expression:"infoUser.corporate_account_personal_info.fax_number"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,2532582536)})],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Additional contact")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Contact Name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info
              .additional_contact_name
              ),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info
              , "additional_contact_name", $$v)},expression:"infoUser.corporate_account_personal_info\n              .additional_contact_name\n              "}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,3108487471)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Contact Number"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info
              .additional_contact_number
              ),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info
              , "additional_contact_number", $$v)},expression:"infoUser.corporate_account_personal_info\n              .additional_contact_number\n              "}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,3378482891)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Additional Contact Email"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":50},model:{value:(_vm.infoUser.corporate_account_personal_info
              .additional_contact_email
              ),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info
              , "additional_contact_email", $$v)},expression:"infoUser.corporate_account_personal_info\n              .additional_contact_email\n              "}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,1469447044)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Notes"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"autocomplete","state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true,"maxlength":250},model:{value:(_vm.infoUser.corporate_account_personal_info.notes),callback:function ($$v) {_vm.$set(_vm.infoUser.corporate_account_personal_info, "notes", $$v)},expression:"infoUser.corporate_account_personal_info.notes"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}],null,false,1948169642)})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }